<ng-container *ngIf="officeId">
  <div style="padding-top: 30px" *ngFor="let data of officeAgentsService.templateData[id]; let last = last">
    <div style="font-size: 36px; font-weight: 400; text-align: left">
      {{ data.agentType.officeHeading }}
    </div>
    <div style="display: flex; flex-wrap: wrap">
      <nz-card
        nzBorderless
        *ngFor="let agent of data.agents"
        style="width: 300px; margin: 30px 30px 30px 0"
        [nzBodyStyle]="{ display: 'grid', 'grid-gap': '10px', padding: '20px' }"
      >
        <a class="agent" style="text-decoration: none" [href]="agent.pathName">
          <div class="photo" [style]="'background-image: url(' + agent.photos[0].derivatives.searchResult + ')'"></div>
          <div class="name">{{ agent.firstNames[0] }} {{ agent.lastNames[0] }}</div>
          <svg class="icon" width="40" height="40" viewBox="0 0 1024 1024">
            <title>external link</title>
            <path d="M489.6 579.2l249.6-249.6v124.8h64v-227.2h-240v64h121.6l-243.2 243.2z"></path>
            <path
              d="M969.6 60.8h-752v121.6h-134.4v761.6h716.8v-134.4h169.6v-748.8zM768 915.2h-652.8v-697.6h105.6v598.4h547.2v99.2zM905.6 748.8h-624v-624h624v624z"
            ></path>
          </svg>
        </a>
        <button *ngIf="agent.appointmentLinks[0]?.url" nz-button nzType="primary">
          <a [href]="agent.appointmentLinks[0].url">Book Appointment</a>
        </button>
        <button *ngIf="agent.travelLinks[0]?.url" nz-button nzType="primary">
          <a [href]="agent.travelLinks[0].url">Get Travel Quote</a>
        </button>
        <button *ngIf="agent.insuranceLinks[0]?.url" nz-button nzType="primary">
          <a [href]="agent.insuranceLinks[0].url">Get Insurance Quote</a>
        </button>
        <button *ngIf="agent.phones[0]" nz-button nzType="default">
          <a [href]="'tel:' + agent.phones[0]">Call {{ agent.phones[0] }}</a>
        </button>
      </nz-card>
    </div>
    <nz-divider style="margin: 10px 0" *ngIf="!last"></nz-divider>
  </div>
  <!--
  <div (mouseover)="setProperty('showTools', '1')" class="json-editor"
       *ngIf="metaData.user.id === '1' && metaData.editor">
    <div>
      <button mat-raised-button color="primary" (click)="initializeJsonData()">
        Reset defaults from the code, all changes in the editor below will be lost
      </button>
    </div>
    <div>
      <ava-json-editor></ava-json-editor>
    </div>
  </div>
  -->
</ng-container>
